.btn {
  font-weight: 700;
  padding: 0.25rem 1rem;
  font-size: 1.5rem;
  letter-spacing: 2px;
  border-width: 3px;
}

.search-form {
  .btn-search {
    background-image: url(./images/icon/seacrh.png);
    width: 36px;
    height: 30px;
    background-size: auto;
  }
  .form-control {
    background-clip: unset;
  }
}

.btn-with-loader {
  position: relative;
}

.btn-primary {
  &.onloading {
    color: $primary;
    &:hover {
      color: $primary;
    }
  }
}
