.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.sm {
        width: 27px;
        height: 27px;
    }
}
.lds-dual-ring {
    &:after {
        content: '';
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $primary;
        border-color: $primary transparent $primary transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    &.white {
        &:after {
            border-color: $white transparent $white transparent;
        }
    }
    &.dark,
    &.black {
        &:after {
            border-color: $dark transparent $dark transparent;
        }
    }

    &.sm {
        &:after {
            width: 20px;
            height: 20px;
            margin: 0;
            border-width: 2px;
        }
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
