/** Pioneer Normalizer **/
body {
  font-family: Helvetica, Arial, sans-serif;
}

a {
  transition: all 320ms;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}

h1 {
  font-size: 1.5rem;
  &.page-not-found {
    font-size: 3rem;
  }
}
h2 {
  font-size: 1.25rem;
}

/** End of: Pioneer Normalizer */

/** Custom Styling */
.ml-min-1 {
  margin-left: -15px;
}
.mr-min-1 {
  margin-right: -15px;
}
.ml-min-05 {
  margin-left: 0.5rem;
}

.bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768) {
  .mr-min-md-1 {
    margin-right: -15px;
  }
}

/** End of: Custom Styling */

/** Body styling */
.page {
  background-color: $light;
  background-image: url(./images/background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.main {
  padding-top: 50px;
  min-height: 60vh;
  padding-bottom: 60px;
}

.section-footer {
  min-height: 10vh;
  text-align: center;
}

/** End of: Body styling */

/** HOME Tile Menu */
.tile-menu {
  background-image: url(./images/cards/car.jpg);
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.75rem;
  height: 200px;
  position: relative;

  &.marine {
    background-image: url(./images/cards/marine.jpg);
  }
  &.rdrive {
    background-image: url(./images/cards/rdrive.jpg);
  }
  &.promotional-tools {
    background-image: url(./images/cards/promotion-tools.jpg);
    font-size: 46px;
  }
  &.help {
    background-image: url(./images/cards/help.jpg);
  }

  .tile-name {
    z-index: 2;
    &.text {
      color: $white;
    }
  }

  .hover-background {
    background-color: $primary;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity 320ms;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &:hover {
    text-decoration: none;
    .hover-background {
      opacity: 1;
    }
  }
}
/** End of: HOME Tile Menu */

.btn-transparent {
  background-color: transparent;
  border: none;
  padding: 0;
  &:focus,
  &.focus {
    box-shadow: unset;
  }
}

.logo {
  img {
    width: 180px;
    height: 30px;
    @media (min-width: 768px) {
      width: auto;
      height: auto;
    }
  }
}

/** Hack for 5 column **/
.col-md-24 {
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    flex: 0 0 19.9998%;
    max-width: 19.9997%;
    padding: 0 15px;
  }

  &.about-tile {
    &:nth-child(1) {
      padding-right: 5px;
    }
    &:nth-child(5) {
      padding-left: 5px;
    }
    &:nth-child(2),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      padding-left: 5px;
      padding-right: 5px;
    }
    .tile-menu {
      @media (min-width: 576px) {
        height: 55px;
      }
      @media (min-width: 769px) {
        height: 120px;
      }
    }
  }
}
