.section-breadcrumb {
  background-color: transparent;
  @media (min-width: 768px) {
    background-color: $secondary;
  }
  width: 100%;

  .breadcrumb {
    min-height: 39px;
    font-size: 1rem;
    margin-bottom: 0;
    background-color: inherit;
    text-transform: uppercase;
    padding: 7px 0;

    a {
      color: $dark;
    }
    li {
      display: block;
      flex: 0 0 auto;
    }
    .breadcrumb-item {
      & + .breadcrumb-item {
        &::before {
          content: ' ';
          @media (min-width: 768px) {
            background-image: url(./images/icon/breadcrumb-right.png);
          }
          background-image: url(./images/icon/breadcrumb-left.png);
          background-size: 7px 12px;
          background-repeat: no-repeat;
          display: inline;
          padding: 0px 8px;
          background-position: 2px 2px;
        }
      }
    }
  }

  .mobile-breadcrumb {
    .breadcrumb-item {
      &::before {
        content: ' ';
        background-image: url(./images/icon/breadcrumb-left.png);
        background-size: 7px 12px;
        background-repeat: no-repeat;
        display: inline;
        padding: 0px 8px;
        background-position: 2px 2px;
      }
    }
  }
}
