.table-categories {
  thead {
    th {
      border-top: none;
      text-align: center;
      border-bottom: none;
      font-size: 1.25rem;
      @media (max-width: 767px) {
        span {
          display: none;
        }
      }
      &:first-child {
        width: 80%;
        padding-left: 0;
        text-align: left;
        @media (max-width: 767px) {
          span {
            display: inline;
          }
        }
      }
      &:nth-child(2) {
        @media (max-width: 767px) {
          width: 70px;
        }
      }
    }
  }
  tbody {
    td {
      text-align: center;
      border-bottom: 1px solid $grey;
      border-top: none;
      vertical-align: middle;
      &:first-child {
        width: 80%;
        padding-left: 0;
        text-align: left;
      }
      &.files {
        white-space: nowrap;
      }
      @media (max-width: 767px) {
        .file-count {
          color: #878787;
        }
      }
    }
  }
  &.table-files {
    thead {
      th {
        &:first-child {
          width: 75px;
          @media (max-width: 767px) {
            width: auto;
            display: none;
            span {
              display: none;
            }
          }
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 135px;
          @media (max-width: 767px) {
            width: auto;
          }
        }
        &:nth-child(2) {
          width: auto;
          text-align: left;
        }
      }
    }
    tbody {
      td {
        &:first-child {
          width: 75px;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 135px;
          @media (max-width: 767px) {
            width: auto;
          }
        }
        &:nth-child(2) {
          width: 50%;
          @media (max-width: 767px) {
            width: auto;
          }
          text-align: left;
        }
      }
    }
  }
}

.btn-sorting {
  color: $dark;
  letter-spacing: normal;
  font-size: 1.25rem;
  padding: 0;
  border: 0;
  line-height: 1;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $dark;
  }

  &:after {
    content: '';
    display: inline-block;
    height: 9px;
    width: 14px;
    background-image: url(./images/icon/arrow-up.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 5px;
  }
  &.desc {
    &:after {
      background-image: url(./images/icon/arrow-down.png);
    }
  }
}
