.navigation-header {
  height: 50px;
}
.nav-link {
  color: $light;
  position: relative;
  padding: 0 1rem 1.063333rem;
  font-size: 1.25rem;

  &.auth-link {
    font-size: 1rem;
  }

  &.active,
  &:hover {
    color: $dark;
  }
}
.navigation-header {
  line-height: 1;
}

.nav-page {
  .nav-link {
    &:after {
      width: calc(100% - 2rem);
      height: 6px;
      content: '';
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      right: 0;
      opacity: 0;
      transition: all 320ms;
    }
    &.active,
    &:hover {
      color: $light;
      &:after {
        opacity: 1;
      }
    }
  }
}

.humberger-icon {
  display: block;
  width: 33px;
  height: 25px;
  background-color: transparent;
  background-image: url(./images/icon/hamburger.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.mobile-menu {
  position: fixed;
  top: 0;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  z-index: 100;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }

  .menu-overlay {
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .menu-wrapper {
    position: relative;
    right: 0;
    width: 85%;
    flex-wrap: wrap;
    background-color: $primary;
    padding: 1.5rem 15px 0.825rem;
    z-index: 102;
  }

  .menu-close {
    color: $white;
    float: right;
    .close-menu-icon {
      display: block;
      width: 33px;
      height: 30px;
      background-color: transparent;
      background-image: url(./images/icon/btn-close-mobile.png);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 2px;
    }
  }

  .nav {
    flex-direction: column;

    .nav-link {
      padding: 0;
      margin: 0 0 7px;

      &:after {
        display: none;
      }
    }
  }

  .search-form {
    .form-group {
      margin-bottom: 0;
      .form-control-search {
        height: 40px;
        border-right-width: 0;
      }
      .btn-search {
        background-color: $dark;
        border-color: $dark;
        background-size: 28px 25px;
        height: 40px;
        width: 48px;
        background-size: 35.42px 26.29px;
        background-image: url('./images/icon/search-icon-mobile.png');
      }
    }
  }
  .col-12 {
    margin-bottom: 40px;
  }
}
