.modal-dialog {
  position: absolute;
  width: auto;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1052;
}

.preview-modal {
  .modal-backdrop {
    background-color: transparent;
  }
  .modal-body {
    background-color: #e9e9e9;
  }
  .modal-content {
    border: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .modal-header {
    background: #e9e9e9;
    padding: 0.5rem 0;
    border-bottom: 0;
  }

  .btn-modal-close {
    padding: 0;
    color: #979797;
    font-size: 1rem;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
  .modal-preview-loading {
    min-height: 50px;
  }
}
