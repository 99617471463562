//
@import './variables';

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';

// @import '~bootstrap/scss/bootstrap-grid';
// @import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/nav';
// @import '~bootstrap/scss/forms';

// @import '../../node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap.scss';
@import './nav';
@import './button';
@import './table';
@import './breadcrumb';
@import './forms';
@import './modal';
@import './loader';
@import './styles';
